$color__primary: #2e3c43;
$color__secundary: #cfd8dc;
$color__tertiary: #263238;
$color__bgcolor: #006064;
$color__white: #cfd8dc;
$color__black: #2e3c43;
$color__danger: #e53935;
$color__dangerbg: #ffebee;
$color__Link: #cfd8dc;
$color__Link--hover: #00838f;

%transicao {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

%scrollBar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: $color__white;
}
%scrollBar::-webkit-scrollbar {
  width: 12px;
  background-color: $color__primary;
}

%scrollBar::-webkit-scrollbar-thumb {
  @extend %transicao;
  border-radius: 5px;
  background-color: $color__bgcolor;
}
%scrollBar::-webkit-scrollbar-thumb:hover {
  @extend %transicao;
  border-radius: 5px;
  background-color: $color__primary;
}

.BtnTheme:active {
  border: 1px solid $color__Link--hover;
  color: $color__Link--hover;
  background-color: $color__tertiary;
  padding: 5px 10px;
}
.BtnTheme {
  border: 1px solid $color__Link--hover;
  color: $color__Link--hover;
  background-color: $color__tertiary;
  padding: 5px 10px;
  margin: 0px 5px;
}

.BtnTheme:hover,
.BtnTheme.BtnTheme--active {
  @extend %transicao;
  border: 1px solid $color__tertiary;
  color: $color__white;
  background-color: $color__Link--hover;
}

.BtnTheme.BtnTheme--active:hover {
  @extend %transicao;
  border: 1px solid $color__tertiary;
  color: $color__white;
  background-color: $color__Link--hover;
}
